import React from "react";
import "./section-columns.scss";
import Section from "../section/section";
import SectionText from "../section-text/section-text";
import SectionColumn from "../section-column/section-column";
import Glide from '@glidejs/glide';
import "@glidejs/glide/dist/css/glide.core.min.css";
export default class SectionColumns extends React.Component {
    constructor(){
        super();
        this.state = {
            slider: React.createRef(),
        }
    }

    componentDidMount(){
        if(typeof window !== 'undefined'){
            new Glide(this.state.slider.current,{
                type: 'carousel',
                perView: 3,
                gap: 0,
                autoplay: 2000,
                breakpoints: {
                    770: {
                        perView: 1
                    },
                    1000: {
                        perView: 2
                    },
                    1200: {
                        perView: 3
                    },
                    1400: {
                        perView: 1
                    },
                    1500: {
                        perView: 2
                    }
                }
            }).mount();
        }
    }

    getSlider(){
        if(typeof window !== 'undefined'){
            return <div className="glide" ref={this.state.slider}>
                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                            {this.props.columns.map( (column,index) => {
                                return <li  key={index} className="glide__slide">
                                    <SectionColumn title={column.title} description={column.description} /> 
                                </li>
                            })}
                        </ul>
                    </div>
                    <div className="glide__nav" data-glide-el="controls[nav]">
                        <button data-glide-dir="=0" aria-label="button"></button>
                        <button data-glide-dir="=1" aria-label="button"></button>
                    </div>
                </div>
        }else{
            return this.props.columns.map( (column,index) => {
                return <SectionColumn key={index}  title={column.title} description={column.description} />
            })
        }
    }

    render() {
        return <Section 
            resetNav={this.props.resetNav}
            navClass={this.props.navClass}
            text={<SectionText 
                title={this.props.title} 
                description={this.props.description}
                afterContent={this.props.afterContent} 
                className="section-text--container--small"
            />}
            content={this.getSlider()} />
    }
  }
