import React from "react";
import AOS from 'aos';
import "./section.scss";

export default class Section extends React.Component{
    getClassName(){
        let extraClass = this.props.extraClass || ''; 
        
        const classes = ['section', extraClass ];
        const propsClass = this.props.type ? `section--${this.props.type}` : 'section--primary';

        classes.push(propsClass);

        return classes.join(' '); 
    }

    componentDidMount(){
        this.aos = AOS;
        this.aos.init({
            duration: 500
        });
    }
    
    componentDidUpdate() {
        this.aos.refresh();
    }

    render(){
        return <section 
            className={this.getClassName()} 
            data-nav-class={this.props.navClass}
            data-reset-nav={ this.props.resetNav || false}
            >
            <div className="wrapper">
                <div className="section--text" data-aos="fade-up">
                    {this.props.text}
                </div>
                <div className="section--content" data-aos="fade-up">
                    {this.props.content}
                </div>
            </div>
        </section>
    }
}