import React from "react";
import PropTypes from "prop-types";
import "./section-text.scss";
import SectionTitle from "../section-title/section-title";
import SectionDescription from "../section-description/section-description";

const SectionText = ({title,description,afterContent,className}) => (
    <div className={`section-text--container ${className}`}>
        <SectionTitle content={title} priority={3}/>
        <div className="section-text--description">
            <SectionDescription content={description}/>
        </div>
        {afterContent}
    </div>
);

SectionText.propTypes = {
    title: PropTypes.object,
    description: PropTypes.string,
    afterContent: PropTypes.object,
  }
  
SectionText.defaultProps = {
    title: null,
    description: null,
    afterContent: null,
}

export default SectionText

