import React from "react";
import "./section-column.scss";

export default class SectionColumn extends React.Component{
    DynamicComponent = null;

    render(){
        return <figure className="section-column">
            <figcaption className="section-column--caption">
                <h4 className="section-column--title">{this.props.title}</h4>
                <p className="section-column--description">{this.props.description}</p>
            </figcaption>
        </figure>
    }
}