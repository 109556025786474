import React from "react";
import PropTypes from "prop-types";
import "./section-title.scss";

const SectionTitle = ({content,priority}) => {
    const Heading = `h${priority}`;

    return <Heading className="section-title">{content}</Heading>;
};

SectionTitle.propTypes = {
    content: PropTypes.object,
    priority: PropTypes.number
}
  
SectionTitle.defaultProps = {
    content: null,
    priority: 1,
}

export default SectionTitle